<template>
    <v-container
        class="container"
        v-if="loading == false && error == false">
        <v-row>
            <v-col cols="12"
                ><h1 class="tw-font-bold tw-text-secondary tw-text-center md:tw-text-left tw-text-3xl tw-underline tw-underline-offset-1 tw-decoration-primary tw-decoration-4 tw-mb-4">
                    {{ $t('reservedArea.investments.investments') }}
                </h1></v-col
            >
            <!-- Breadcrumbs -->
            <!-- <v-col cols="12" class="tw-hidden md:tw-block">
                <div class="tw-text-lg tw-text-secondary tw-text-left">
                    <v-breadcrumbs :items="breadCrumbItems" active-class="!tw-font-semibold" class="!tw-pl-0">
                        <template v-slot:divider>
                            <v-icon icon="mdi-chevron-right"></v-icon>
                        </template>
                    </v-breadcrumbs>
                </div>
            </v-col> -->
            <!-- Cards -->
            <v-col
                cols="12"
                sm="6"
                md="6"
                xl="6"
                xxl="3"
                class=""
                v-for="i in 4"
                :key="i">
                <v-card
                    class="tw-mx-auto"
                    variant="flat"
                    rounded="lg">
                    <template v-slot:title>
                        <p
                            v-if="i == 1"
                            class="tw-font-bold tw-text-secondary tw-text-left tw-text-lg">
                            {{ $t('reservedArea.investments.acquisitionValue') }}
                        </p>
                        <p
                            v-if="i == 2"
                            class="tw-font-bold tw-text-secondary tw-text-left tw-text-lg">
                            {{ $t('reservedArea.investments.currentValue') }}
                        </p>
                        <p
                            v-if="i == 3"
                            class="tw-font-bold tw-text-secondary tw-text-left tw-text-lg">
                            {{ $t('reservedArea.investments.distributedValue') }}
                        </p>
                        <p
                            v-if="i == 4"
                            class="tw-font-bold tw-text-secondary tw-text-left tw-text-lg">
                            {{ $t('reservedArea.investments.nextDistribution') }}
                        </p>
                    </template>
                    <v-card-actions>
                        <v-list-item class="w-100">
                            <!-- Acquisition Value -->
                            <v-list-item-title v-if="i == 1">
                                <p class="tw-font-bold tw-text-secondary tw-text-left tw-text-lg tw-mb-3">
                                    {{ getAcquisitionValue(customerInvestments) }}
                                </p>
                            </v-list-item-title>
                            <!-- Current Value -->
                            <v-list-item-title v-if="i == 2">
                                <p class="tw-font-bold tw-text-secondary tw-text-left tw-text-lg tw-mb-3">
                                    {{ getCurrentValue(customerInvestments) }}
                                </p>
                            </v-list-item-title>
                            <!-- Distributed Value -->
                            <v-list-item-title v-if="i == 3">
                                <p class="tw-font-bold tw-text-secondary tw-text-left tw-text-lg tw-mb-3">
                                    {{ getDistributedValue(customerInvestments) }}
                                </p>
                            </v-list-item-title>
                            <!-- Next Distribution -->
                            <v-list-item-title v-if="i == 4">
                                <p class="tw-font-bold tw-text-secondary tw-text-left tw-text-lg tw-mb-3">
                                    {{ getNextDistributionValue(customerInvestments) }}
                                </p>
                            </v-list-item-title>
                            <v-list-item-text>
                                <div class="tw-flex tw-items-center tw-justify-center tw-h-6 tw-w-20 tw-bg-primary-light tw-rounded">
                                    <p class="tw-text-secondary tw-text-sm">{{ $t('reservedArea.investments.total') }}</p>
                                </div>
                            </v-list-item-text>
                            <template v-slot:append>
                                <div class="tw-flex tw-justify-center tw-items-center tw-h-12 tw-w-12 tw-bg-gray-default tw-rounded-full">
                                    <v-list-item-icon>
                                        <v-icon>
                                            <v-img
                                                v-if="i == 1"
                                                src="/images/ReservedArea/icons/wallet.svg"></v-img>
                                            <v-img
                                                v-if="i == 2"
                                                src="/images/ReservedArea/icons/graph.svg"></v-img>
                                            <v-img
                                                v-if="i == 3"
                                                src="/images/ReservedArea/icons/euro.svg"></v-img>
                                            <v-img
                                                v-if="i == 4"
                                                src="/images/ReservedArea/icons/calendar-silhouette.svg"></v-img>
                                        </v-icon>
                                    </v-list-item-icon>
                                </div>
                            </template>
                        </v-list-item>
                    </v-card-actions>
                </v-card>
            </v-col>
            <!-- Table -->
            <v-col cols="12">
                <v-data-table-server
                    v-model:items-per-page="itemsPerPage"
                    :items-per-page-text="$t('reservedArea.investments.investmentsPerPage')"
                    :headers="headers"
                    :items-length="totalItems"
                    :items="serverItems"
                    :loading="loading"
                    class="!tw-rounded-lg"
                    item-value="name"
                    @update:options="loadItems">
                    <template #top>
                        <div class="!tw-text-center md:!tw-text-left !tw-text-secondary tw-text-xl !tw-font-bold !tw-border-none tw-p-4">
                            {{ $t('reservedArea.investments.investmentFunds') }}
                        </div>
                    </template>
                    <!-- type -->
                    <template #[`item.type`]="{ item }">
                        <div
                            v-if="item.selectable.type == 'Gestão'"
                            class="tw-flex tw-items-center tw-justify-center tw-h-6 tw-w-24 tw-bg-gray-light tw-rounded">
                            <p class="tw-text-secondary tw-text-center tw-text-xs">{{ $t('reservedArea.investments.management') }}</p>
                        </div>
                        <div
                            v-if="item.selectable.type == 'Subscrição'"
                            class="tw-flex tw-items-center tw-justify-center tw-h-6 tw-w-24 tw-bg-primary-light tw-rounded">
                            <p class="tw-text-secondary tw-text-center tw-text-xs">{{ $t('reservedArea.investments.subscription') }}</p>
                        </div>
                    </template>
                    <!-- fund -->
                    <template #[`item.fund`]="{ item }">
                        <div>
                            <p class="tw-font-semibold tw-text-secondary tw-text-left tw-text-sm">{{ item.selectable.name }}</p>
                            <p class="tw-text-secondary tw-text-left tw-text-sm tw-line-clamp-2">{{ item.selectable.excerpt }}</p>
                        </div>
                    </template>
                    <!-- acquisition value -->
                    <template #[`item.acquisitionValue`]="{ item }">
                        <div>
                            <p
                                v-if="item.selectable.investments"
                                class="tw-font-semibold tw-text-secondary tw-text-base">
                                {{ getAcquisitionValue(item.selectable.investments) }}
                            </p>
                            <p
                                v-else
                                class="tw-font-semibold tw-text-secondary tw-text-base">
                                -
                            </p>
                        </div>
                    </template>
                    <!-- current value -->
                    <template #[`item.currentValue`]="{ item }">
                        <div>
                            <p
                                v-if="item.selectable.investments && item.selectable.id == 3"
                                class="tw-font-semibold tw-text-secondary tw-text-base">
                                {{ $t('reservedArea.investments.underUpdate') }}
                            </p>
                            <p
                                v-else-if="item.selectable.investments && item.selectable.id != 3"
                                class="tw-font-semibold tw-text-secondary tw-text-base">
                                {{ getCurrentValue(item.selectable.investments) }}
                            </p>
                            <p
                                v-else
                                class="tw-font-semibold tw-text-secondary tw-text-base">
                                -
                            </p>
                        </div>
                    </template>
                    <!-- distributed value -->
                    <template #[`item.distributedValue`]="{ item }">
                        <div>
                            <p
                                v-if="item.selectable.investments"
                                class="tw-font-semibold tw-text-secondary tw-text-base">
                                {{ getDistributedValue(item.selectable.investments) }}
                            </p>
                            <p
                                v-else
                                class="tw-font-semibold tw-text-secondary tw-text-base">
                                -
                            </p>
                        </div>
                    </template>
                    <!-- capital distribution -->
                    <template #[`item.capitalDistribution`]="{ item }">
                        <div>
                            <p
                                v-if="item.selectable.investments"
                                class="tw-font-semibold tw-text-secondary tw-text-base">
                                {{ getNextDistributionValue(item.selectable.investments) }}
                            </p>
                            <p
                                v-else
                                class="tw-font-semibold tw-text-secondary tw-text-base">
                                -
                            </p>
                        </div>
                    </template>
                    <template #[`item.actions`]="{ item }">
                        <v-btn
                            variant="flat"
                            :to="{ path: `investment/${item.selectable.id}` }"
                            type="submit"
                            class="bg-primary text-black font-weight-bold !tw-text-base !tw-py-1"
                            max-height="none"
                            height="auto"
                            rounded="xs"
                            size="x-large">
                            {{ $t('reservedArea.investments.seeMore') }}
                        </v-btn>
                    </template>
                </v-data-table-server>
            </v-col>
        </v-row>
    </v-container>
    <div
        v-if="loading"
        class="tw-h-full tw-flex tw-items-center tw-justify-center">
        <v-progress-circular
            indeterminate
            color="primary"
            model-value="20"></v-progress-circular>
    </div>
    <!-- Error -->
    <div
        v-if="error"
        class="tw-h-full tw-items-center tw-justify-center tw-flex tw-gap-x-2 tw-p-4 md:tw-p-0 tw-text-center">
        <p class="tw-font-semibold">{{ $t('shared.error') }} ({{ errorMessage }} - {{ currentDate }})</p>
        <v-icon
            class="tw-text-primary"
            icon="mdi-alert-circle"></v-icon>
    </div>
</template>

<script setup lang="ts">
    import { ref, computed } from 'vue';
    import { VDataTableServer } from 'vuetify/labs/VDataTable';
    import { useI18n } from 'vue-i18n';
    import { useLanguageStore } from '@/store/modules/language';
    import { getAllFunds, getCustomerFunds } from '@/api/dbn';
    import { me } from '@/api/user';
    import { useAuthStore } from '@/store/modules/auth';
    import { v4 as uuidv4 } from 'uuid';

    import moment from 'moment';
    import * as Sentry from '@sentry/vue';
    import { get } from 'lodash';

    const { t } = useI18n();

    //language store
    const languageStore = useLanguageStore();
    const language = computed(() => languageStore.getLanguage);

    //breadcrumbs
    // const breadCrumbItems = ref([
    //     {
    //         title: t('reservedArea.breadcrumbs.dashboard'),
    //         disabled: false,
    //         href: '/reserved-area/dashboard',
    //     },
    //     {
    //         title: t('reservedArea.breadcrumbs.investments'),
    //         disabled: false,
    //         active: $router.currentRoute.value.name == 'Investment' ? true : false,
    //         href: '/reserved-area/investment',
    //     },
    // ]);

    //data table
    const itemsPerPage = ref(15);
    const headers = ref([
        {
            title: t('reservedArea.investments.type'),
            align: 'start',
            sortable: false,
            key: 'type',
        },
        { title: t('reservedArea.investments.fund'), key: 'fund', width: '30%' },
        { title: t('reservedArea.investments.acquisitionValue'), key: 'acquisitionValue' },
        { title: t('reservedArea.investments.currentValue'), key: 'currentValue' },
        { title: t('reservedArea.investments.distributedValue'), key: 'distributedValue' },
        { title: t('reservedArea.investments.capitalDistribution'), key: 'capitalDistribution' },
        { title: '', key: 'actions', align: 'end' },
    ]);

    const totalItems = ref(0);
    const loading = ref(true);
    const serverItems = ref([] as any[]);

    //store token
    const authStore = useAuthStore();
    const token = authStore.getToken;
    let user = authStore.getUser;

    //customer id and funds
    const allFunds = ref([]);
    const customerFunds = ref([]);
    const customerInvestments = ref([]);

    const error = ref(false);
    const errorMessage = ref('Error');

    //current datetime
    const currentDate = moment().format('DD/MM/YYYY HH:mm:ss');

    //fetch funds
    const fetchFunds = async () =>
        await getAllFunds(language.value, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                allFunds.value = response.data;
                //add type to each fund
                allFunds.value.forEach((fund: any) => {
                    fund.type = 'Subscrição';
                });
                fetchCustomerFunds();
            })
            .catch((err) => {
                console.log(err);
                if (err.toString().includes('TypeError')) errorMessage.value = '404';
                else errorMessage.value = err;
                error.value = true;
                loading.value = false;
            });

    //fetch customer funds, add type to each fund and format values
    const fetchCustomerFunds = async () => {
        await getCustomerFunds(language.value, user?.dbnCode ? user?.dbnCode : user?.dbns[0]?.code, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                customerFunds.value = response.data;

                customerFunds.value.forEach((customerFund: any) => {
                    if (customerFund.id == 3) {
                        customerFund.investments.forEach((investment: any) => {
                            investment.value = null;
                        });
                    }

                    // handle duplicate customer funds
                    // duplicates exists cause the same fund can have different investment categories
                    let sameFunds = customerFunds.value.filter((fund: any) => fund.id === customerFund.id);
                    if (sameFunds.length > 1) {
                        customerFund.investments = [...new Map(sameFunds.flatMap((fund) => fund.investments).map((inv) => [inv.category, { ...inv, investmentId: uuidv4() }])).values()];
                    }

                    customerInvestments.value = [
                        ...new Map(
                            [
                                ...customerInvestments.value,
                                ...customerFund.investments.map((inv) => ({
                                    ...inv,
                                    fundId: customerFund.id,
                                    investmentId: inv.investmentId || uuidv4(),
                                })),
                            ].map((inv) => [inv.investmentId, inv]),
                        ).values(),
                    ];

                    // Definir o tipo do fundo
                    const index = allFunds.value.findIndex((fund: any) => fund.id === customerFund.id);
                    if (index !== -1) {
                        customerFund.type = 'Gestão';
                        allFunds.value[index] = customerFund;
                    }
                });
            })
            .finally(() => {
                console.log(customerInvestments.value);
                loadItems({ page: 1, itemsPerPage: 15, sortBy: [] });
            })
            .catch((err) => {
                console.log(err);
                if (err.toString().includes('TypeError')) errorMessage.value = '404';
                else errorMessage.value = err;
                error.value = true;
                loading.value = false;
            });
    };

    //load table items
    const loadItems = async ({ page, itemsPerPage, sortBy }: any) => {
        Sentry.captureMessage('----------------- Load Items --------------------');
        //sort documents
        if (sortBy.length) {
            const sortKey = sortBy[0].key;
            const sortOrder = sortBy[0].order;
            allFunds.value = allFunds.value.sort((a, b) => {
                const sortA = a[sortKey];
                const sortB = b[sortKey];
                if (sortOrder === 'asc') {
                    return sortA > sortB ? 1 : -1;
                } else if (sortOrder === 'desc') {
                    return sortA < sortB ? 1 : -1;
                }
                return 0;
            });
            Sentry.captureMessage('All Funds Sorted: ' + JSON.stringify(allFunds.value));
        }
        //by default, the table is sorted by type (Gestão -> Subscrição)
        allFunds.value.sort((a: any, b: any) => {
            if (a.type == 'Gestão' && b.type == 'Subscrição') return -1;
            if (a.type == 'Subscrição' && b.type == 'Gestão') return 1;
            return 0;
        });
        Sentry.captureMessage('All Funds Sorted By Type: ' + JSON.stringify(allFunds.value));
        serverItems.value = allFunds.value.slice((page - 1) * itemsPerPage, page * itemsPerPage);
        totalItems.value = allFunds.value.length;
        loading.value = false;
        Sentry.captureMessage('Server Items: ' + JSON.stringify(serverItems.value));
        Sentry.captureMessage('Total Items: ' + totalItems.value);
    };

    const init = async () => {
        await me(
            {
                populate: ['role', 'dbns', 'children'],
            },
            {
                headers: {
                    Authorization: `Bearer ${authStore.getToken}`,
                },
            },
        ).then((response) => {
            user = response.data.user;
            authStore.setUser(response.data.user);
            fetchFunds();
        });
    };

    //force init
    init();

    //methods to get values (acquisitionValue, currentValue, distributedValue, nextDistributionValue)
    //get acquisition value
    const getAcquisitionValue = (investments) => {
        const sum = investments.reduce((sum, investment) => {
            if (investment.initialValue == null || investment.initialValue == 0) return sum;
            return sum + investment.initialValue;
        }, 0);

        // Convert the sum to a string with enough precision to avoid rounding issues
        const sumStr = sum.toFixed(10); // Ensure sufficient decimal places for precision
        let decimalPlaces = sumStr.split('.')[1]?.replace(/0+$/, '').length || 0;

        if (decimalPlaces < 2) decimalPlaces = 2;

        return sum.toLocaleString('de-DE', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: decimalPlaces,
            maximumFractionDigits: decimalPlaces,
        });
    };
    //get current value
    const getCurrentValue = (investments) => {
        //if all investments are from fundId 3 (Growth Fund 1), return under update
        const allFundsAreGrowthFund = investments.every((investment) => investment.fundId == 3);
        if (allFundsAreGrowthFund) return t('reservedArea.investments.underUpdate');

        const sum = investments.reduce((sum, investment) => {
            if (investment.value == null || investment.value == 0) return sum;
            return sum + investment.value;
        }, 0);

        // Convert the sum to a string with enough precision to avoid rounding issues
        const sumStr = sum.toFixed(10); // Ensure sufficient decimal places for precision
        let decimalPlaces = sumStr.split('.')[1]?.replace(/0+$/, '').length || 0;

        if (decimalPlaces < 2) decimalPlaces = 2;

        return sum.toLocaleString('de-DE', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: decimalPlaces,
            maximumFractionDigits: decimalPlaces,
        });
    };
    //get distributed value
    const getDistributedValue = (investments) => {
        const sum = investments.reduce((sum, investment) => {
            if (investment.distributedValue == null || investment.distributedValue == 0) return sum;
            return sum + investment.distributedValue;
        }, 0);

        // Convert the sum to a string with enough precision to avoid rounding issues
        const sumStr = sum.toFixed(10); // Ensure sufficient decimal places for precision
        let decimalPlaces = sumStr.split('.')[1]?.replace(/0+$/, '').length || 0;

        if (decimalPlaces < 2) decimalPlaces = 2;

        return sum.toLocaleString('de-DE', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: decimalPlaces,
            maximumFractionDigits: decimalPlaces,
        });
    };
    //get next distribution value
    const getNextDistributionValue = (investments) => {
        const sum = investments.reduce((sum, investment) => {
            if (investment.nextDistributionValue == null || investment.nextDistributionValue == 0) return sum;
            return sum + investment.nextDistributionValue;
        }, 0);

        // Convert the sum to a string with enough precision to avoid rounding issues
        const sumStr = sum.toFixed(10); // Ensure sufficient decimal places for precision
        let decimalPlaces = sumStr.split('.')[1]?.replace(/0+$/, '').length || 0;

        if (decimalPlaces < 2) decimalPlaces = 2;

        return sum.toLocaleString('de-DE', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: decimalPlaces,
            maximumFractionDigits: decimalPlaces,
        });
    };
</script>

<style scoped></style>
<style>
    .v-table--density-default > .v-table__wrapper > table > tbody > tr > td,
    .v-table--density-default > .v-table__wrapper > table > thead > tr > td,
    .v-table--density-default > .v-table__wrapper > table > tfoot > tr > td {
        height: calc(var(--v-table-row-height, 30px) + 50px);
    }
    /* .v-field__input {
        margin-top: 0.625rem;
    } */
</style>
